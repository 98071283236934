import React, { Component } from 'react'

export default class Solutions extends Component {
    render() {
        return (
            <div>
                                <section className='banner drainage'>
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-8">
                                <h1>Drainage</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="company-features">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Drainage & Irrigation & Landscaping Division Overview</h2>
                                <p>Core Services Replacement and installation of hot water systems. We install and replace all kinds of hot water systems and geysers. We able to help with gravity-fed geysers, high-pressure geysers, solar systems and heat pumps. We service blocked drains and specialize in drain cleaning. Leak detection: Find and locate Water leaks and burst pipes. Bathroom and Kitchen Refurbishments. ICEC provides a mix of sufficient services to both the residential and corporate sectors all over UK and GCC, whilst at the same time, ensuring adherence to respectable service delivery. The management at ICEC Group has 15 years plumbing experience. We are able to deliver in accordance with high GCC levels of standards and practices. Replacement and installation of underground and above ground drainage pipe. We do all sizes from 40mm pvc pipes to 225mm pvc pipes. Underground drainage to above ground drainage. Replacement and installation of storm water drains, down pipes and gutters. Replacement and installation of water pipes. copper, pex pipe and plastic. We install, repair and replace all kinds of pipes from copper pipes to pex pipes as well as plastic pipes. Replacement and installation of all sanitaryware and chromeware. We install and replace all kinds of sanitaryware, including taps, toilets, basins, vanities, sinks, showers, shower doors, urinals, baths and bidets. We only use the finest quality brands. Balancing of uneven water pressure: Installing a valve that creates a balance between your hot and cold water lines so that your hot and cold taps operate at the same pressure. </p>
                                <p>The following values for Drainage Services reflect goals and priorities, our professional values and the nature of our work.</p>
                            </div>
                            <div className="col-md-8 flex">
                                <div className="points">
                                    <p>
                                        Environmental Stewardship & Public Health: Drainage Services continues to be a leader in environmental performance and public health and safety standards. We meet or exceed municipal, provincial and federal requirements for stewardship of the environment, public health, and public safety.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 flex">
                                <div className="points">
                                    <p>
                                        Sustainability: Drainage Services plays a key role in the long-term sustainability of our City. We adopt integrated sustainable practices that benefit citizens and the environment; we repair, replace and build new infrastructure to achieve sustainable urban development goals.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 flex">
                                <div className="points">
                                    <p>
                                        Excellent Service: Drainage Services achieves excellence in customer service. We are innovative in finding ways to deliver services that are both efficient and effective. We stay current with emerging technology and best practices to provide valued service to customers, partners, and stakeholders.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 flex">
                                <div className="points">
                                    <p>
                                        Engaged and Inspired Employees: Drainage Services is proud of the expertise of its team. Our work environment is innovative, positive, team-oriented and safe. We perform our jobs with integrity and transparency.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 flex">
                                <div className="points">
                                    <p>
                                        Collaboration: Drainage Services recognizes the increasing cross-jurisdictional and cross functional nature of environmental, asset management, and financial challenges; hence the need for collaborative processes to find solutions
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="company-features">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p>The following six align with the Drainage Master Plan. Each Goal is composed of Key Strategic Directions: </p>
                            </div>
                            <div className="col-md-8 flex">
                                <div className="points">
                                    <p>
                                        HEALTHY LIVING AND ENVIRONMENTAL STEWARDSHIP Drainage Services is committed to reducing its impact on our environment and protect the health of our people and ecosystem through continuous improvement towards zero pollutants and contaminants
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 flex">
                                <div className="points">
                                    <p>
                                        CITIZENS WELL SERVED Drainage Services desires to ensure our customers feel well served by the utility. This means that citizens feel they receive good value for the cost of drainage services, which includes uninterrupted and seamless service, flood risk reduction, and timely and enthusiastic customer support.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 flex">
                                <div className="points">
                                    <p>
                                        SUPPORTING AND ENHANCING GROWTH AND DEVELOPMENT As the City continues to explore urban densification; residential, commercial and industrial development; and regional collaboration; Drainage Services will continue to work closely with other City departments and developers to ensure our neighborhoods are sustainable and have minimal environmental impact.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 flex">
                                <div className="points">
                                    <p>
                                        SUSTAINABLE INFRASTRUCTURE Drainage Services is committed to a proactive, optimized and integrated asset management approach linking operational data with long term planning. This approach ensures the continued integrity of our assets, reduces the need for emergency repairs, and supports innovation.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 flex">
                                <div className="points">
                                    <p>
                                        FISCAL RESPONSIBILITY Fiscal responsibility means that we have a well-planned, balanced, and managed budget that clearly demonstrates to customers the value they receive for the rates they pay. As a fiscally responsible utility, Drainage Services has planned our finances to enable us to support our operations and goals in a manner that is fair and equitable to current and future ratepayers.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-8 flex">
                                <div className="points">
                                    <p>
                                        ORGANIZATIONAL EXCELLENCE Drainage Services is committed to optimizing the way we manage our people, equipment, and our workplace by providing a healthy, engaging, productive workplace where employees feel a strong sense of pride and purpose. We implement sound management practices and processes and engage effective communication.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="company-features">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 small-section">
                                <h2>EMERGING ISSUES FLOOD MITIGATION</h2>
                                <p>Historical severe storm events that have resulted in neighborhood flooding has prompted Drainage Services to develop flood mitigation strategies to address the issue. While implementation of the recommended capital improvements is underway, a proactive program continues to evolve from the City-wide Flood mitigation program. Retrofitting neighborhood features with storm water ponds and sewer upgrades is resource intensive and must be staged in order to balance these works with other critical infrastructure management that the utility implements. The impact of the city-wide improvements is expected to be more substantial, and the impact quantified as concept plans are better known. In addition, this will also address how we can mitigate the potential risks associated with climate change.</p>
                            </div>
                            <div className="col-md-12 small-section">
                                <h2>SEWER ASSET RENEWAL</h2>
                                <p>
                                    As sewer systems age the importance for a utility to systematically manage the condition of these assets, including timely repair/replacement, is increasingly important in order to ensure minimal service disruptions to citizens. The investigation is focused on capacity (utilization), condition (structural) and functionality. This holistic approach will involve significant investigation and data management resources. The goal of these efforts is to develop asset upgrade and replacement plans for the short term (3 years), mid-term (10 years) and long term (20-75 year).
                                </p>
                            </div>
                            <div className="col-md-12 small-section">
                                <h2>CAPACITY IMPLEMENTATION PLAN</h2>
                                <p>Drainage Services capital budget will see a significant increase over the next 10. To manage this effectively, Drainage Services is developing a plan to address these growing demands. The objective of the Drainage Capacity Implementation Plan (DCIP) is to ensure the successful delivery of the approved Drainage Services Capital Program with a focus on the Flood Mitigation, Neighborhood Renewal, System Rehabilitation, and Sanitary Servicing Strategy programs. Each of the focus areas will validate the capital and operational needs by determining our current ability (capacity, processes and delivery approach) to deliver these key programs and further explore viable alternatives to ensure delivery of the committed work. The strategy will take into consideration the lag time to build competency as well as the scalability of expanding the existing functions; while the Action Plan will include key activities, estimated timelines and resource requirements. Throughout the process, there will be a continuous engagement with stakeholders both internally within the City as well as externally with industry partners.</p>
                            </div>
                            <div className="col-md-12 small-section">
                                <img src="./images/drainage.png" alt="" />
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
