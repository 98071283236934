export const MenuItems = [
    {
        'id': 0,
        'title': 'Home',
        'link': '/'
    },
    {
        'id': 'tc0013',
        'title': 'Training Courses',
        'link': '/training'
    },
    {
        'id': 1,
        'title': 'Solutions',
        'link': '#',
        'subMenu':[
            {
                'id': 211,
                'title': 'Drainage',
                'link': '/drainage'
            },
            {
                'id': 212,
                'title': 'Interior Design',
                'link': '/interior-design'
            },
            {
                'id': 2,
                'title': 'Architectural',
                'link': '/arch-civil'
            },
            {
                'id': 3,
                'title': 'Civil Construction',
                'link': '/civil-construction'
            },
            {
                'id': 4,
                'title': 'Construction Management',
                'link': '/construction-management'
            },
            {
                'id': 5,
                'title': 'Design Services',
                'link': '/design-services'
            },
            {
                'id': 6,
                'title': 'Facades',
                'link': '/facades'
            },
            {
                'id': 7,
                'title': 'Heritage Services',
                'link': '/heritage-services'
            },
            {
                'id': 8,
                'title': 'Landscape Management',
                'link': '/landscape-management'
            },
            {
                'id': 9,
                'title': 'MEP Works',
                'link': '/mep-works'
            },
            {
                'id': 10,
                'title': 'PMCM',
                'link': '/pmcm'
            },
            {
                'id': 11,
                'title': 'ICEC Technical Services',
                'link': '/technical-services'
            },
            {
                'id': 12,
                'title': 'Smart City',
                'link': '/smart-city'
            },
            {
                'id': 13,
                'title': 'Site Supervision',
                'link': '/site-supervision'
            },
            
        ]
    },
    {
        'id': 14,
        'title': 'About Us',
        'link': '/company-history'
    },
    {
        'id': 15,
        'title': 'Contact Us',
        'link': '/contact'
    },
]