import React, { Component } from 'react'

export default class MEPWorks extends Component {
    render() {
        return (
            <>
                <section className='banner mep-works'>
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-8">
                                <h1>MEP Works</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                            <h2 className="pre-heading">Services</h2>
                            <p>Contracting and maintenance services for mechanical, electrical, plumbing and safety systems, including design, supply, installation and maintenance for the construction sector throughout India, Qatar, Europe and Kingdom of Saudi Arabia.</p>
                            </div>
                        </div>
                        <div className="row mt-40 align-items-center">
                            <div className="col-md-6 m-order-2">
                                <div className="text-wrapper">
                                <h4 className="pre-heading">Systems</h4>
                                <ul >
                                    <li>Central HVAC Systems.</li>
                                    <li>Electrical Systems.</li>
                                    <li>Low Current & Control Systems.</li>
                                    <li>Fire Alarm & Extinguishing Systems.</li>
                                    <li>Plumbing Systems.</li>
                                    <li>Mechanical Systems.</li>
                                </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                            <img src="./images/mep-1.jpg" alt="" />    
                            </div>
                        </div>
                        <div className="row mt-40">
                            <div className="col-md-12">
                            <h4 className="pre-heading">Warranty and after- sales maintenance services</h4>    
                            <p>ICEC maintenance Control Department have dedicated teams to provide Preventive Maintenance and Breakdown Repairs during and after warranty period. </p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
