import React, { Component } from 'react'

export default class CivilConstruction extends Component {
    render() {
        return (
            <>
                <section className='banner civil-construction'>
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-8">
                                <h1>Civil Construction</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <p>By virtue of engagement & managing large scale construction projects, Civil Construction becomes inevitable. We have successfully completed number of civil contracts on various projects meeting the stringent deadlines and strict project specification for our customers in the Middle East and Europe like Masdar City, Ministry of Interiors Doha, Mshreib properties, Illumina Centre, Angila Ruskins University  etc. a few to mention. Our experienced & competent team and a range of construction equipment required for carrying out civil construction works smoothly makes us successful.</p>
                            </div>
                        </div>
                        <div className="row services">
                            <div className="col-md-12">
                                <h2 className="heading">Our Services</h2>    
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Surveying</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Super Structure</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Pipe Rack</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Pavement</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Earthwork</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Ware House</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Landscaping Work</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Foundation</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Architectural Finishing</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Plant Road</h4>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
