import React, { Component } from 'react'

export default class HeritageServices extends Component {
    render() {
        const services = ["Safety & Risk assessment for Heritage Buildings and Historical Sites.","Detail Documentation and architectural survey for Heritage Building nd Historical Sites.","Heritage Management Plan.","Tourism Development Plan.","Conservation and Rehabilitation for Heritage Buildings.","Project upervision for Heritage Buildings and Historical sites.","Heritage Safeguard Strategies and Guidelines.","Provide Research and Historical Content.","Heritage Consultancy.","Renovation and Restoration.","Museums and Exhibitions Display Design."]
        return (
            <>
                <section className='banner heritage-services'>
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-8">
                                <h1>Heritage Services</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 m-order-2">
                                <div className="text-wrapper">
                                    <h2 className="pre-heading">Heritage Specialists</h2>
                                    <p>Our specialized team includes all the experts needed to thoroughly investigate, report, design, supervise and provide innovative adaptive solutions related to cultural and historical heritage. We have delivered expert results for small and large private developers as well as government entities offering the best practice, cost effective and heritage regulations complaint solutions to deliver our projects.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src="./images/heritage-1.jpg" alt="" />
                            </div>
                        </div>
                        <div className="row align-items-center mt-40">
                            <div className="col-md-6">
                                <img src="./images/heritage-2.jpg" alt="" />
                            </div>
                            <div className="col-md-6 m-order-2">
                                <div className="text-wrapper">
                                    <h2 className="pre-heading">Consultancy Professionals</h2>
                                    <p>As one of the first consultants that provide heritage services in Qatar and Saudi Arabia, we can offer the best consultation and advice on cultural and historical, indigenous and non- indigenous heritage planning and management so they understand all options through every stage of the project.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row services mt-100">
                            <div className="col-md-12 mb-40">
                                <h2 className="pre-heading">Services Provided:</h2>
                            </div>
                           {services && services.map((service, index) => (
                              <div className="col-md-4 service" key={index}>
                                  <p className='counter'>{index + 1}</p>
                                  <h4 className="pre-heading">{service}</h4>
                                </div>
                           ))}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
