import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div>
                <div className="whatsapp">
                    <a href="https://wa.me/919596349870" target="_blank" rel="noreferrer">
                        <img src="./images/whatsapp.png" alt="" />
                    </a>

                </div>
                <div className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <img className='footer-logo' src="./images/logo-inv.png" alt="" />
                            </div>
                            <div className="col-md-6">
                                <p>Email: <a href="mailto:info@icecomp.com">info@icecomp.com</a></p>
                                <p>Call Us:<br/> 
                                <a href="tel:+917889479044">+91 7889 479 044</a>, 
                                <a href="tel:+919596349870"> +91 9596 349 870</a><br/>
                                <a href="tel:+919999983582">+91 9999 983 582</a>, 
                                <a href="tel:+918929880169"> +91 8929 880 169</a><br/>
                                </p>
                                <p>Head Office:<br/>Chanapora Near Receiving Station Firdous Complex<br />Srinagar Jammu & Kashmir 190015
                                </p>
                                <p>Branch Office:<br /> A, 1,2 Floor DMRC Building Connecting Tower Opposite<br />
                                    New Delhi railway station Ajmeri Gate<br />
                                    110002
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="powered-by">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <p>icecomp.com | {new Date().getFullYear()}</p>
                            </div>
                            <div className="col-md-6 text-end">
                                <p>Developed By <a href="https://wa.me/917006519288" target="_blank" rel='noreferrer noopener'>Aamir Beigh</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
