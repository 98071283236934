import React, { Component } from 'react'

export default class TechnicalServices extends Component {
    render() {
        return (
            <>
                <section className='banner bim'>
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-8">
                                <h1>ICEC Techinal Services</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p>We provide building information modelling (BIM), estimation and scheduling services with a high degree of accuracy, timelines and quality. Our services are delivered by experienced BIM engineers, estimators and schedulers who utilize the latest industry technologies for BIM, creation, design visualization, construction planning, quantity take off and estimation, scheduling and 4-D site logistics planning for enhanced understanding of the project, promoting collaboration and providing our clients with critical insights into their projects.</p>
                            </div>
                        </div>
                        <div className="row mt-40 align-items-center">
                            <div className="col-md-6 m-order-2">
                                <div className="text-wrapper">
                                    <h4 className="pre-heading">Estimation Service</h4>
                                    <ul>
                                        <li>Quantity takeoffs</li>
                                        <li>Cost estimation</li>
                                        <li>Value engineering</li>
                                        <li>Change management study</li>
                                        <li>Cost control</li>
                                        <li>Post contract cost audit</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6"><img src="./images/bim-1.jpg" alt="" /></div>
                        </div>
                        <div className="row align-items-center mt-40">
                            <div className="col-md-6">
                                <img src="./images/road.jpg" alt="" />
                            </div>
                            <div className="col-md-6 m-order-2">
                                <div className="text-wrapper">
                                    <h4 className="pre-heading">Road</h4>    
                                    <p>The requirement-related expansion of the road networks and their further development is one of the major interests of our traffic engineers, road and rail road planners constructing new roads, and airports, may lead to effective regional and structural improvements thus, comfortable connection on safe routes and transportation systems for both individuals and goods are an absolute must. We provide full and qualified services for private and public road construction projects from planning to implantation. Our competency and long term experience allow for superior concept and their feasibility. Therefore, we focus on secure planning processes bearing in mind future developments</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-40 align-items-center">
                            <div className="col-md-6 m-order-2">
                                <div className="text-wrapper">
                                
                            <h4 className="pre-heading">Area of specialization</h4>
                            <ul>
                                <li>Roads and highways</li>
                                <li>Site development works</li>
                                <li>Drainage and sewage systems</li>
                                <li>Signage systems</li>
                                <li>Underpasses, bridges and interchanges</li>
                            </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                            <img src="./images/concrete.jpg" alt="" />
                        </div>
                        </div>

                    </div>
                </section>
            </>
        )
    }
}
