import React, { Component } from 'react'

export default class DesignServices extends Component {
    render() {
        return (
            <>
                <section className='banner design-services'>
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-8">
                                <h1>Design Services</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row services">
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Lead design management</h4>
                            </div>    
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Main Consultant</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">A/E Design Supervision</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Feasibility Studies</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Concept, Schematic, Detail Design & Construction documents</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Tender</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Construction Supervision</h4>
                            </div>
                            <div className="col-md-4 service">
                                <h4 className="pre-heading">Commissioning & Hand Over</h4>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
