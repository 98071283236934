import React, { Component } from 'react'

export default class SiteSupervision extends Component {
    render() {
        return (
            <>
                <section className='banner supervision'>
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-8">
                                <h1>Site Supervision Consultant</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p>ICEC international project managers are dedicated in providing complete project management from design and detailed engineering, including solutions to all the common needs of Architecture and Civil Engineering fields of construction industries. </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="pre-heading">Area of Specialization</h4>
                                <p>Overall control and management of the pre-contract project team and representatives of the main line of communication with the owner representative, authorities and municipalities.</p>
                                <ul className='ul-disc'>
                                    <li>Tender Assistance</li>
                                    <li>Preparation of Work Schedules
                                        <ul className='sub-li'>
                                            <li>Contractual period of performance – in accordance with the contract duration</li>
                                            <li>Internal work schedule – inclusive of all stages of work, services approval upto turnover to client. To integrate the major and minor activities in sequence or in order of pre-requisite in accordance with the contractual obligations and deliverables, submittal stage, review cycles that will form part of the project schedule.</li>
                                        </ul>
                                    </li>
                                    <li>Request for Information and Quotation</li>
                                    <li>Detailed design development</li>
                                    <li>Agency and Services approval</li>
                                    <li>Project financial – the Project Manager monitoring his progress shall be conscientious of the progress and the schedule of payment defined in the contract. The PM shall advice contracts/legal and accounting to invoice client reference to an approved progress of work by the client or his progress assessment. </li>
                                    <li>Correspondences -  the PM upon receipt of letter document shall immediately issue an acknowledgement receipt and indicating on his letter the target date of when he will receive a reply or submittal on his concern.</li>
                                    <li>Quality Control/Quality Assurance </li>
                                    <li>Documentation and Reports </li>
                                    <li>Coordination and Responsibility 
                                        <ul className="sub-li">
                                            <li>Client</li>
                                            <li>Municipalities, Agencies and Concerned Service Department</li>
                                            <li>Agency Coordinators, project team</li>
                                            <li>Project Controls and HOD</li>   
                                            <li>Contracts/Legal and Accounting </li> 
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
