import React, { Component } from 'react'

export default class LandscapeManagement extends Component {
    render() {
        const services = ["Hardscape designing.","Softscape designing.","Site lighting.","Site grading.","Water features.","Site signage.","Site furniture."]
        return (
            <>
                <section className='banner landscape-management'>
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-8">
                                <h1>Landscape Management</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row services">
                            <div className=" col-md-12 mb-40">
                                <p>Landscape services provided as following:</p>
                            </div>
                            {services && services.map((service, index)=>(
                                <div className="col-md-4 service">
                                    <p className="counter">{index + 1}</p>
                                    <h4 className="pre-heading">{service}</h4>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </>


        )
    }
}
