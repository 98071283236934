import React, { Component } from 'react'

export default class CompanyHistory extends Component {
    render() {
        return (
            <div>
                <section className="company-features">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>
                                    Company Introduction
                                </h2>
                                <p>
                                    ICEC Group is an Infrastructure, Construction, Mechanical, Electrical and Plumbing contracting company in UK, Ireland & Qatar. ICEC specializes in design & Build, supply, install, service, upgrade, maintenance of Electro-Mechanical Systems & Networks, Utilities and Equipment. Our scope covers all aspects of the engineering process: documentation, submittals, approval, shop drawings, coordinating drawings, commissioning, start-up, as-built according to contract Programs, project time schedules – complying consultant specifications, international standards & codes, safety & quality & logistic plans.
                                </p>
                            </div>
                        </div>
                        <div className="row align-center">
                            <div className="col-md-6 intro-text">
                                <p>Our business activity extends to cover all areas of electro-mechanical works including but not limited to: </p>
                                <ul>
                                    <li>Electrical substations </li>
                                    <li>MEP Plants Works</li>
                                    <li>Electrical MV Systems</li>
                                    <li>Electrical LV systems</li>
                                    <li>ELV Networks </li>
                                    <li>Tele-Communication </li>
                                    <li>Security Systems </li>
                                    <li>Earthing Systems </li>
                                    <li>Lightening protections systems </li>
                                    <li>Surge Protection systems </li>
                                    <li>Piping & Plumbing Works </li>
                                    <li>HVAC Works</li>
                                    <li>Ducting & Thermal Insulation Works</li>
                                    <li>Fire Fighting Works </li>
                                    <li>Industrial Installations Works</li>
                                    <li>Drainage Services (Design, Construction and Maintenance)</li>
                                    <li>Sewage Treatment Plants (Design, Construction & Maintenance)</li>
                                    <li>Solar Systems Solutions </li>
                                    <li>All underground Services</li>
                                    <li>Roads and buildings</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <img src="./images/company-intro.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="company-features">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Company History</h2>
                                <p>ICEC Group works with the highest efficiency in accordance with the latest technologies fully compliant with international standards of quality, security and safety. We utilize trained and experienced staff for the implementation and delivery of projects with commitment to contract terms, conditions, and preservation to the environment, which can be seen in our environmental plan. ICEC Group has specialized departments and each department is divided to cover the different types and styles of property, plants, buildings and construction. The divisions based on distinctive features are as follows:</p>
                            </div>
                        </div>
                        <div className="row align-center">
                            <div className="col-md-6">
                                <img src="./images/company-history.jpg" alt="" />
                            </div>
                            <div className="col-md-6 intro-text">
                                <ul>
                                    <li>Commercial & Businesses </li>
                                    <li>Administrative & Organizations</li>
                                    <li>Governmental & Public </li>
                                    <li>Touristic & Hotels, Resorts</li>
                                    <li>Educational & Cultural</li>
                                    <li>Trade Centers & Malls, Hyper</li>
                                    <li>Sport & Clubs</li>
                                    <li>Industrial & Factorial</li>
                                    <li>Sites & Open Areas</li>
                                </ul>
                                <p>We execute all projects in accordance with technical and financial strategies to fit the distinctive character of the construction projects and to achieve the maximum benefit for our customers.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="company-features">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-4 flex">
                                <div className="cards">
                                    <img src="./images/management.png" alt="" />
                                    <h4 className="pre-heading">Our People</h4>
                                    <p>At ICEC we foster a culture based on employee empowerment through improved learning, capability building, and knowledge sharing. Leveraging on solid training and early access to the latest technologies and trends, we ensure our globally integrated and multidisciplinary teams are fully equipped.</p>
                                    <p>Our Geographies and Market Sectors exist in a synergic relationship whereby insights, experiences and lessons learnt are shard through open channels of communication. Our people are industry leaders, agile and capable individuals who can meet and surpass the needs of our clients and embrace change in a rapidly evolving industry. </p>
                                </div>
                            </div>
                            <div className="col-md-4 flex">
                                <div className="cards">
                                    <img src="./images/businessman.png" alt="" />
                                    <h4 className="pre-heading">Our Perspective</h4>
                                    <p>We are problem solvers, idea champions, and innovative thinkers who perceive challenges as an opportunity to push boundaries. With eyes wide open, and by approaching projects from a 360 degrees’ perspective, we look at every project as a living element, aiming to capture every unique aspect from the technical to the aesthetic; notwithstanding its complete integration into the urban fabric, the community and the environment.</p>
                                </div>
                            </div>
                            <div className="col-md-4 flex">
                                <div className="cards">
                                    <img src="./images/capability.png" alt="" />
                                    <h4 className="pre-heading">Our Capabilities</h4>
                                    <p>By capitalizing on our multidisciplinary on- house resources and a global pool of knowledge, experiences, and insights, ICEC is a one- stop solution to complex projects through various delivery methods.
                                        We rely on our inspiration and confidence to spread head change and challenge standard industry constraints. This help us to cultivate a growing portfolio of partners and clients and to deliver the world’s highest ambitions.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 flex">
                                <div className="cards">
                                    <img src="./images/design.png" alt="" />
                                    <h4 className="pre-heading">Sustainability </h4>
                                    <p>
                                        Environmental Engineering Management </p><p>
                                        Green Building Implementation is aiming at reducing or eliminating impacts of building design, construction and operation in order to achieve sustainability goals. These goals are hard to be achieved without understanding and analyzing the current environmental circumstances where the building is located. Our environmental Engineering Management service is being established to assist our clients in integrating the Green Building process as well as meet local environmental requirements.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="director-section company-features">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="heading">
                                    Message From Directors
                                </h2>

                            </div>
                            <div className="col-md-6 flex  text-center">
                                <div className="cards">
                                    <img src={"./images/ehsan.jpg"} alt="" />
                                    <h4 className="pre-heading">
                                        Ehsan Ali
                                    </h4>
                                    <h5 className="sub-heading">
                                        Co-Founder & Managing Director
                                    </h5>
                                    <p>
                                        At ICEC our main focus is delivery not only for our clients, but for all parties
                                        involved in our projects. We very much believe in an integrated project
                                        delivery system which is completely transparent from conception to
                                        delivery. At ICEC we see this as how the construction industry is evolving
                                        especially in Europe & in the Middle East on some of our larger projects.
                                        We are looking forward to bringing this proactive ethos to India
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 flex text-center">
                                <div className="cards">
                                    <img src={"./images/mohamed.jpg"} alt="" />
                                    <h4 className="pre-heading">
                                        Mohamed Imran Khan
                                    </h4>
                                    <h5 className="sub-heading">
                                        Executive Director
                                    </h5>
                                    <p>
                                        We are proud to be a proactive manager of problems before they arise, with our
                                        professional team, ICEC assures to provide different services to various clienteles.
                                        We are proud to be one of renowned Project/Construction Management, Architectural &
                                        Engineering consultancies having successfully managed wide range of projects.
                                        We employ experienced professionals, understand our client's requirements and objective,
                                        then take ownership of each and every project where we are involved.
                                        No matter large or small, Building or Civil Engineering projects, our mission is to deliver
                                        our client's vision as per the defined time scales.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="affiliates">
                    <div className="container">
                        <div className="row">
                            <h2 className="heading">Our Team Affiliates</h2>
                            <div className="col-md">
                                <img src="./images/aff-1.png" alt="" />
                            </div>
                            <div className="col-md">
                                <img src="./images/aff-2.jfif" alt="" />
                            </div>
                            <div className="col-md">
                                <img src="./images/aff-3.png" alt="" />
                            </div>
                            <div className="col-md">
                                <img src="./images/aff-4.png" alt="" />
                            </div>
                            <div className="col-md">
                                <img src="./images/aff-5.png" alt="" />
                            </div>
                            <div className="col-md">
                                <img src="./images/aff-6.png" alt="" />
                            </div>
                            <div className="col-md">
                                <img src="./images/aff-7.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
