import React, { Component } from 'react'
import './Homepage.styles.css'
import { MenuItems } from '../Sidebar/menuList'
import { Link } from 'react-router-dom'
export default class Homepage extends Component {
    render() {
        return (
            <div>
                <div className="hero">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="hero-text">
                                    <h1><strong>Interstellar Contract and Engineering Consultancy</strong></h1>
                                </div>
                            </div>
                            <div className="col-md-4 tagline">
                                <h2> Build Tomorrow, Today</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="intro about-brief">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div>
                                    <p>
                                        ICEC Group is an Infrastructure, Construction, Mechanical, Electrical and Plumbing contracting company in UK, Ireland & Qatar. ICEC specializes in design & Build, supply, install, service, upgrade, maintenance of Electro-Mechanical Systems & Networks, Utilities and Equipment. Our scope covers all aspects of the engineering process: documentation, submittals, approval, shop drawings, coordinating drawings, commissioning, start-up, as-built according to contract Programs, project time schedules – complying consultant specifications, international standards & codes, safety & quality & logistic plans.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="web-pages">
                    <div className="container">
                        <div className="row services">
                            <div className="col-md-12 mb-40">
                                <h4 className="pre-heading">
                                    Explore Our Solutions
                                </h4>
                            </div>
                            {MenuItems && MenuItems.map((menus) => (
                                <>
                                    {menus.subMenu && menus.subMenu.map((service, index) => (

                                            <Link to={service.link} className="col-md-4 service">
                                                <p className="counter">{index + 1}</p>
                                                <h4 className="pre-heading">{service.title}</h4>
                                            </Link>

                                    ))}
                                </>
                            ))}
                        </div>

                    </div>
                </section>
            </div>
        )
    }
}
