import React, { useRef, useState } from 'react'
import emailjs from 'emailjs-com';
export default function Contact() {
    const form = useRef();
    const [submit, setSubmit] = useState("Submit")
    const [message, setMessage] = useState(null)
    const [err, setErr] = useState(null)
    const sendEmail = (e) => {
        e.preventDefault();
        setSubmit("Submitting")
        emailjs.sendForm('service_uvpc96i', 'template_h3n3qzb', form.current, '8eaQQgRYARnvLLmba')
            .then(() => {
                setErr("success")
                setSubmit("Submitted")
                setMessage("The Email has Been Sent Successfully")
            }, () => {
                setErr("warning")
                setMessage("Oops! There was an Issue, Try Again")
            });
    };
    return (
        <>
            <section className='banner contact'>
                <div className="container">
                    <div className="row text-center justify-content-center">
                        <div className="col-md-8">
                            <h1>Contact</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="col-md-8 contact-form">
                                <div className="contact-message">
                                    {message && (
                                        <p className={"alert alert-"+err} role="alert">{message}</p>
                                    )}
                                </div>
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input type="text" name="user_name" />
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" name="user_email" />
                                    </div>
                                    <div className="form-group">
                                        <label>Message</label>
                                        <textarea name="message" rows={5}/>
                                    </div>
                                    <div className="form-group">
                                        <input type="submit" value={submit} className="button"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

