import React, { Component } from 'react'
import './Sidebar.styles.css'
import { MenuItems } from './menuList'
import { Link } from 'react-router-dom'

export default class Sidebar extends Component {
  constructor() {
    super()
    this.state = {
      dropDown: false,
    }
    this.toggleDropDown = this.toggleDropDown.bind(this)
  }
  toggleDropDown(index) {
    if(this.state.dropDown === index){
      this.setState({dropDown: null})
    }
    else{
    this.setState({dropDown: index})
    }
  }
  render() {
    return (
      <div className={`${this.props.openMenu ? 'toggle-menu' : ''} sidebar`}>
        <div className="close-sidebar" onClick={this.props.toggleMenu}><i className='fas fa-times'></i></div>
        <div className="sidebar-menu">
          <ul>
            {MenuItems.map((menuItem, index) => (
              <>
                {menuItem.subMenu ? (
                  <li key={index} className='drop-down-trigger' onClick={()=>{this.toggleDropDown(index)}}>
                    
                    <Link to={menuItem.link}>
                      {menuItem.title}
                      {index === this.state.dropDown ? (<i className='fas fa-angle-down'></i>) : (<i className='fas fa-angle-right'></i>)}
                      </Link>
                      {index === this.state.dropDown && (
                        <ul className='sub-menu'>
                          {menuItem.subMenu.map((subMenuItem) => (
                            <li key={index}>
                              <Link to={subMenuItem.link} onClick={this.props.toggleMenu}>
                                {subMenuItem.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    
                  </li>
                ) : (
                  <li key={menuItem.id}>
                    <Link to={menuItem.link} onClick={this.props.toggleMenu}>
                      {menuItem.title}
                    </Link>
                  </li>
                )

                }
              </>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}
