import Headers from "./components/Headers/Headers";
import Homepage from "./components/Homepage/Homepage";
import CompanyHistory from "./components/CompanyHistory/CompanyHistory";
import Solutions from "./components/Solutions/Solutions";
import ArchandCivil from "./components/ArchAndCivil/ArchandCivil";
import { Routes, Route } from "react-router";
import Footer from "./components/footer/Footer";
import CivilConstruction from "./CivilConstruction/CivilConstruction";
import ConstructionManagement from "./ConstructionManagement/ConstructionManagement";
import DesignServices from "./DesignServices/DesignServices";
import Facades from "./Facades/Facades";
import HeritageServices from "./HeritageServices/HeritageServices";
import LandscapeManagement from "./LandscapeManagement/LandscapeManagement";
import MEPWorks from "./MEPWorks/MEPWorks";
import PMCM from "./PMCM/PMCM";
import TechnicalServices from "./TechnicalServices/TechnicalServices";
import SmartCity from "./SmartCity/SmartCity";
import SiteSupervision from "./SiteSupervision/SiteSupervision";
import Contact from "./Contact/Contact";
import Interior from "./Interior/Interior";
import TrainingCourse from "./components/Sidebar/TrainingCourse/TrainingCourse";
function App() {
  return (
    <div>
      <Headers />
      <div className="page">
      <Routes>
        <Route exact path="/" element={<Homepage/>}/>
        <Route path="/company-history" element={<CompanyHistory/>}/>
        <Route path="/drainage" element={<Solutions />}/>  
        <Route path="/arch-civil" element={<ArchandCivil />}/>
        <Route path="/civil-construction" element={<CivilConstruction />}/>
        <Route path="/construction-management" element={<ConstructionManagement />}/>
        <Route path="/design-services" element={<DesignServices />}/>
        <Route path="/facades" element={<Facades />}/>
        <Route path="/heritage-services" element={<HeritageServices />}/>
        <Route path="/landscape-management" element={<LandscapeManagement />}/>
        <Route path="/mep-works" element={<MEPWorks />}/>
        <Route path="/pmcm" element={<PMCM />}/>
        <Route path="/technical-services" element={<TechnicalServices />}/>
        <Route path="/smart-city" element={<SmartCity />}/>
        <Route path="/site-supervision" element={<SiteSupervision />}/>
        <Route path="/interior-design" element={<Interior />}/>
        <Route path="/contact" element={<Contact />}/>
        <Route path="/training" element={<TrainingCourse/>}/>
      </Routes>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
