import React, { Component } from 'react'
import './Header.styles.css';
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar/Sidebar';
import { MenuItems } from '../Sidebar/menuList'
export default class Headers extends Component {
    constructor() {
        super()
        this.state = {
            openMenu: false,
        }
        this.toggleMenu = this.toggleMenu.bind(this)
    }
    toggleMenu() {
        this.setState(
            prevState => (
                { openMenu: !prevState.openMenu }
            )
        )
    }
    render() {
        return (
            <div>
                <div className="header">
                    <div className="container">
                        <div className="row align-center">
                            <div className="col">
                                <div className="logo">
                                    <Link to="/">
                                        <img src="./images/logo1.png" alt="" />
                                    </Link>
                                </div>

                            </div>
                            <div className="col">
                                <div className="menu-button">
                                    <i className="fas fa-bars" onClick={this.toggleMenu}></i>
                                <Sidebar toggleMenu={this.toggleMenu} openMenu={this.state.openMenu}/>
                                </div>
                                <ul className='header-nav'>
                                    {MenuItems.map((menuItem, index) => (
                                        <>
                                            {menuItem.subMenu ? (
                                                <li key={index} className='drop-down-trigger'>

                                                    <Link to={menuItem.link}>
                                                        {menuItem.title}
                                                        <i className='fas fa-angle-down'></i>
                                                    </Link>
                                                        <ul className='sub-menu'>
                                                            {menuItem.subMenu.map((subMenuItem) => (
                                                                <li key={index}>
                                                                    <Link to={subMenuItem.link} onClick={this.props.toggleMenu}>
                                                                        {subMenuItem.title}
                                                                    </Link>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    

                                                </li>
                                            ) : (
                                                <li key={menuItem.id}>
                                                    <Link to={menuItem.link} onClick={this.props.toggleMenu}>
                                                        {menuItem.title}
                                                    </Link>
                                                </li>
                                            )

                                            }
                                        </>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
