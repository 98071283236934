import React, { Component } from 'react'

export default class SmartCity extends Component {
  render() {
    return (
      <>
                      <section className='banner smart-city'>
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-8">
                                <h1>Smart City</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                            <p><strong>Smart Cities:</strong> How technology can deliver a better quality of life</p>
                            <p>Today, cities are moving beyond the pilot stage and using data technologies to deliver results that are more relevant and meaningful to residents.</p>
                            <p>After a decade of trial and error, municipal leaders are realizing that smart-city strategies start with people, not technology, <em>“Smartness”</em>  is not just about installing digital interfaces in traditional infrastructure or streamlining city operations, it is also about using technology and data purposefully to make better decisions and deliver a better quality of life.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 m-order-2">
                                <div className="text-wrapper">
                                <p>Quality of life has many dimensions, from the air residents breathe to how safe they feel walking the streets, the latest report from the Mckinsey Global institute(MGI), Smart cities: Digital solutions for a more livable future, analyzes how dozens of digital applications address these kinds of practical and very human concerns. It finds that cities can use smart technologies to improve some key quality of life indicators by 10 -30% - numbers that translate into lives saved, fewer crime incidents, shorter commutes, a reduced health burden & carbon emissions averted.</p>
                                <ul>
                                    <li>Smart city technologies have substantial unrealized potential to improve the urban quality of life.</li>
                                    <li>A look at current deployment at current cities around the world shows that the most advanced still have a long way to go.</li>
                                    <li>Smart cities change the economics of infrastructure and create room for partnerships and private sector participation</li>
                                </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                            <img src="./images/smart-city-1.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6"><img src="./images/smart-city-2.jpg" alt="" /></div>
                            <div className="col-md-6 m-order-2">
                                <div className="text-wrapper">
                                    <h4 className="pre-heading">What Makes a City Smart?</h4>
                                    <p>Smart cities put data and digital technology to work to make better decisions and improve the quality of life. More comprehensive, real time data gives agencies the ability to watch the events as they unfold, understand how demand patterns are changing, and respond with faster and lower cost solutions.</p>
                                    <p>Three layers work together to make a smart city hum. First Is the technology base, which includes a critical mass of smart phones and sensors connected by high speed communication networks. The second layer consists of specific applications, translating raw data into alerts, insight, and action requires the right tools, and this is where technology providers and app developers come in. The third layer is usage by cities, companies and the public. Many applications succeed only if they are widely adopted and managed to change behavior. They encourage people to use transit during off hours, to change routes, to use less energy and water and to do so at different times of day, and to reduce strains on the health care system through preventive self care.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
      </>
    )
  }
}
