import React, { Component } from 'react'

export default class Interior extends Component {
    render() {
        const services = ["Design Services", "MEP Design","Fit-out Work","Project Management","Joinery Works","Furniture Supply & Installation"]
        return (
            <>
              <section className='banner interior'>
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-8">
                                <h1>Interior Design and Fit Out Works</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 m-order-2">
                                <div className="text-wrapper">
                                    <p>At ICEC we strive for excellence at every level of the project. We are constantly snagging the work as project progress, to safeguard a ‘snag-free’ finish at hand over.</p>
                                    <p>Safety well-being and protection is our primary concern. Even though as a young company we are not yet accredited, we take Health and Safety very seriously and always liaise with the best consultants to ensure all our sites are fully maintained in a risk averse way. </p>
                                    <p>Communication our on-site team has an understanding of the whole spirit of the design so that everyone visualizes what the final result should look like. We work together with our clients as one team, constantly exchanging and transferring ideas, to ensure the smooth running of the project. We keep our clients informed through regular meetings and reports. </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src="./images/interior1.jpg" alt="" />
                            </div>
                        </div>
                        <div className="row services mt-40">
                            <div className="col-md-12 mb-40">
                                <h4 className="pre-heading">
                                Our Services
                                </h4>    
                            </div>
                            {services && services.map((service, index)=>(
                                <div className="col-md-4 service">
                                    <p className="counter">{index + 1}</p>
                                    <h4 className="pre-heading">{service}</h4>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
