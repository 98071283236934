import React, { Component } from 'react'

export default class Facades extends Component {
    render() {
        return (
            <>
                <section className='banner facades'>
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-8">
                                <h1>Facades</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 m-order-2">
                            <div className="text-wrapper">
                                <h2 className="pre-heading">Dynamic Facades</h2>
                                <p>The increasing architectural trend of maximizing glazed surfaces area presents a technological challenge with regards to energy consumption and building sustainability. To address this challenge, building physics research and development has focused on areas such as the development of accurate software tools capable of assessing building energy usage, the development of climatically responsive advanced façade systems, façade integration of renewable energy systems as well as studies into the improvement of internal thermal and acoustic comfort conditions. </p>
                            </div>
                            </div>
                            <div className="col-md-6">
                            <img src="./images/facades-1.jpg" alt="" />
                            </div>
                        </div>
                        <div className="row mt-40 align-items-center">
                            <div className="col-md-6">
                                <img src="./images/facades-2.jpg" alt="" />
                            </div>
                            <div className="col-md-6 m-order-2">
                                <div className="text-wrapper">
                                    <h2 className="pre-heading">Moisture free, Sustainable, Closed Cavity Façade</h2>
                                    <p>The ICEC Group introduced the innovative and very cost-effective moisture-dust Free-Sustainable Closed Cavity Façade, mfree-S CCF, into the high-end building market in 2018. Since then, many projects have been realized all over middle east and Europe.</p>
                                    <p>The mfree SCCF is designed in such a manner that no contamination by dust and condensation is permitted inside the internal cavity of this special double skin façade</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center mt-40">
                            <div className="col-md-6 m-order-2">
                            <div className="text-wrapper">
                                <h2 className="pre-heading">D3- Dual, Dynamic and Durable</h2>
                                <p>Based on double-skin and mfree-SCCF façade technologies, ICEC Group has developed an innovative façade concept: The D3 Façade (Dual, Dynamic, Durable).</p>
                                <p>D3 Façade can be seen as a revolution towards a holistic façade solution, complaint with all major challenges and ready for upcoming performance requirements for environmentally friendly buildings.</p>
                            </div>
                            </div>
                            <div className="col-md-6">
                            <img src="./images/facades-3.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
