import React, { Component } from 'react'

export default class ArchandCivil extends Component {
    render() {
        return (
            <>
                <section className='banner arch-civil'>
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-8">
                                <h1>Architecture and Civil</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <p>
                                    ICEC International Architectural Engineering are dedicated in providing complete detailed engineering design to the needs of common & complex architectural and civil engineering drawings with the application of local and international codes & standards and green building concept, withstanding the design and the project development.
                                </p>
                                <div className="row align-items-center">
                                    <div className="col-md-6 m-order-2">
                                        <ul>
                                            <li>Concept design for all buildings types (commercial, offices, recreational, residential…etc.)</li>
                                            <li>Environmental design and study.</li>
                                            <li>Development concepts design.</li>
                                            <li>Renovation and restoration.</li>
                                            <li>Obtaining the building permit.</li>
                                            <li>Models preparation and animation</li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <img src="./images/architecture.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        </section>
                        <section className='sec-50'>
                            <div className="container">
                        <div className="row m-clm">
                            <div className="col-md-6 flex">
                                <div className="bg-box int-dsn">
                                <h4 className='pre-heading'>Interior Design</h4>
                                    <ul>
                                        <li>Concept design for any function (commercial, residential, offices …etc.)</li>
                                        <li>Furniture design.</li>
                                        <li>Furnishing</li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-md-6 flex">
                            <div className="bg-box urb-dsn">
                            <h4 className='pre-heading'>Urban Planning</h4>
                                    <ul>
                                        <li>Local plan</li>
                                        <li>Land use plan</li>
                                        <li>Master plan</li>
                                        <li>Master plan development</li>
                                        <li>Rough grading</li>
                                    </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2 className="heading">AREA OF SPECIALIZATION</h2>
                                <p>
                                Architectural Detailed Engineering design: for large scale projects such as Mid and High rise Residential Buildings, Horizontal developments, Town Houses, Government Facilities, Offices Buildings, Commercial Centers, Malls and Towers, Theatres and Cinemas, Medical & Health Facilities, Schools, Multipurpose Halls, Ware Houses, Mosque, Parks & Playground, Sports and Recreational facilities, Data Centers and Industrial facilities.
                                </p>
                                <p>
                                Inter - discipline co-ordination of detailed design drawings thereby ensuring the workability, adherence to applicable local and international codes & standards including the green building concept and latest technology for the realization of the project.
                                </p>
                                <p>
                                Architectural Engineering- detailed drawings are based on the following software's:
                                </p>
                                <ul>
                                    <li>AutoCAD 2017</li>
                                    <li>Revit Architecture 2017</li>
                                    <li>Adobe Photoshop</li>
                                    <li>Microsoft office</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
