import React, { Component } from 'react'

export default class PMCM extends Component {
    render() {
        const services = ["Program management","Project management","Peer reviews","Technical due-diligence","Construction management","Project monitoring services","Cost consultancy","Interior Fit-Out management","Building information Modelling"]
        return (
            <>
                <section className='banner pmcm'>
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-8">
                                <h1>PMCM</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 m-order-2">
                                <div className="text-wrapper">
                                <div className="pre-heading">Project Management and Cost Management </div>
                                <p>Serving clients as their primary advocate, we monitor the day- to- day progress, safety and quality of projects from planning through project turnover and closeout.</p>
                                <p>Our services can encompass anything from evaluating the master project schedule and implementing risk management procedures to managing testing and commissioning and overseeing final inspections and punchlists.</p>
                                <p>Whether we are managing design changes to meet budget goals or leveraging extensive industry network to source the most capable and competitive contractors and vendors, ICEC is committed to realizing each clients vision. </p>
                                </div>
                                
                            </div>
                            <div className="col-md-6">
                                <img src="./images/pmcm1.jpg" alt="" />    
                            </div>
                        </div>
                        <div className="row services mt-40">
                            <div className="col-md-12 mb-40"><h4 className="pre-heading">Our Services</h4></div>
                            {services && services.map((service, index) =>(
                                <div className="col-md-4 service">
                                    <p className="counter">{index + 1}</p>
                                    <h4 className="pre-heading">{service}</h4>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
