import React, { Component } from 'react'

export default class ConstructionManagement extends Component {
    render() {
        return (
            <>
                <section className='banner construction-management'>
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-8">
                                <h1>Construction Management</h1>
                            </div>
                        </div>
                    </div>
                </section>
               <section>
                    <div className="container">
                        <div className="row services">
                        <div className="col-md-12">
                        <p>Guarantees: Full on site coordination, quality surveillance, timing control, tasks interfaces, etc. for multi work packages.</p>
                        </div>
                        <div className="col-md-4 service">
                            <h4 className="pre-heading">Tender Support</h4>    
                        </div>
                        <div className="col-md-4 service">
                            <h4 className="pre-heading">Site Organization</h4>    
                        </div>
                        <div className="col-md-4 service">
                            <h4 className="pre-heading">Supplier Coordination</h4>    
                        </div>
                        <div className="col-md-4 service">
                            <h4 className="pre-heading">Work packages</h4>    
                        </div>
                        <div className="col-md-4 service">
                            <h4 className="pre-heading">Quality Assurance</h4>    
                        </div>
                        <div className="col-md-4 service">
                            <h4 className="pre-heading">Commissioning</h4>    
                        </div>
                        <div className="col-md-4 service">
                            <h4 className="pre-heading">Constructability Workshop</h4>    
                        </div>
                        <div className="col-md-4 service">
                            <h4 className="pre-heading">Contract Administration</h4>    
                        </div>
                        <div className="col-md-4 service">
                            <h4 className="pre-heading">Field Logistics</h4>    
                        </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
