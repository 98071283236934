import React, { useRef, useState } from 'react'
import emailjs from 'emailjs-com';
export default function TrainingCourse() {
    const form = useRef();
    const [submit, setSubmit] = useState("Submit")
    const [message, setMessage] = useState(null)
    const [err, setErr] = useState(null)
    const sendEmail = (e) => {
        e.preventDefault();
        setSubmit("Submitting")
        emailjs.sendForm('service_uvpc96i', 'template_c17z2r4', form.current, '8eaQQgRYARnvLLmba')
            .then(() => {
                setErr("success")
                setSubmit("Submitted")
                setMessage("The Email has Been Sent Successfully")
            }, () => {
                setErr("warning")
                setMessage("Oops! There was an Issue, Try Again")
            });
    };
    const courses = [
        'MEP', 'HVAC Design and Drafting', 'Electrical Design and Drafting', 'Plumbing and Fire and Fire Fighting Design',
        'Project Management', 'Testing and Commissioning Management', 'Independent Commissioning Authority', 'Site Supervision Consultant Training',
        'Professional Registration Training For Chartered Engineer by IET UK', 'Professional Registration Training for Chartered Construction Manager by CIOB UK',
        'Professional Registration Training for Chartered Surveyor by RICS UK', 'Project Management Professional (PMP) Training by PMI USA'
    ]
    return (
        <>
            <section className='banner training'>
                <div className="container">
                    <div className="row text-center justify-content-center">
                        <div className="col-md-8">
                            <h1>Training Courses</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="col-md-8 contact-form">
                                <div className="contact-message">
                                    {message && (
                                        <p className={"alert alert-"+err} role="alert">{message}</p>
                                    )}
                                </div>
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="form-group">
                                        <label>Name *</label>
                                        <input type="text" name="user_name" required/>
                                    </div>
                                    <div className="form-group">
                                        <label>Email *</label>
                                        <input type="email" name="user_email" required/>
                                    </div>
                                    <div className="form-group">
                                        <label>Date of Birth *</label>
                                        <input type="date" name="user_dob" required/>
                                    </div>
                                    <div className="form-group">
                                        <label>Contact Number *</label>
                                        <input type="text" name="user_number" required/>
                                    </div>
                                    <div className="form-group">
                                        <label>Select Course *</label>
                                        <select name="user_course" id="" required>
                                            <option value="Select" disabled>Select Course</option>
                                            {courses.map(course => (
                                                <option value={course}>{course}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Address *</label>
                                        <textarea name="address" rows={5} required/>
                                    </div>
                                    <div className="form-group">
                                        <input type="submit" value={submit} className="button"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
